<template lang="pug">
  div
    v-toolbar(
      color="primary"
      dark
      dense
      elevation="0"
    )
      span List
    v-data-table(
      :items="feedingProgramCategoryList"
      :headers="headers"
    )
      template(v-slot:item="{ item }")
        tr
          td {{ item.id }}
          td {{ item.order }}
          td {{ item.stock_category_id }}
          td {{ item.description }}
          //- td {{ item.day_start }} - {{ item.day_end }}
          //- td {{ item.body_weight }}
          //- td {{ item.expected_weight }}
          td
            v-btn(
              color="yellow darken-3"
              text
              x-small
              @click="$emit('edit', item)"
            )
              v-icon mdi-pencil
</template>
<script>
import feedingProgramCategoryRepository from '@/repositories/feeding-program-category.repository'
import { getVars } from '@/libs/api-helper.extra'
import VueRequest from '@/libs/classes/VueRequest.class'

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'id' },
    { text: 'Order', value: 'order' },
    { text: 'Stock Category', value: 'stock_category_id' },
    { text: 'Description', value: 'description' },
    // { text: 'Day Range', value: 'dayStart' },
    // { text: 'Body Weight (kg)', value: 'body_weight' },
    // { text: 'Expected Weight', value: 'expected_weight' },
    { text: 'Config', sortable: false },
  ],
})

const getRequestVarNames = baseName => ({
  loading: baseName + 'Getting',
  list: baseName + 'List',
  error: baseName + 'GetErrors',
})

export default {
  name: 'FeedingProgramCategoryTable',
  data: () => ({
    ...getVars('feeding-program-category'),
    ...tableVars(),
  }),
  created () {
    this.getFeedingProgramCategory()
    this.listenToWebsocketEvents()
  },
  methods: {
    getFeedingProgramCategory () {
      if (this.feedingProgramCategoryGetting) return
      const requestVarNames = getRequestVarNames('feedingProgramCategory')
      const handler = new VueRequest(this, requestVarNames)
      const repository = feedingProgramCategoryRepository.index
      handler.execute(repository)
    },
    listenToWebsocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    dbStoreEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      this.routeWebsocketDBStoreEvents({ model, data })
    },
    dbUpdateEvent ({ model, data }) {
      if (!model || this.$objectEmpty(data)) return
      this.routeWebsocketDBUpdateEvents({ model, data })
    },
    routeWebsocketDBUpdateEvents ({ model, data }) {
      if (model === 'FeedingProgramCategory') {
        this.feedingProgramCategoryDBUpdateEvent(data)
      }
    },
    routeWebsocketDBStoreEvents ({ model, data }) {
      if (model === 'FeedingProgramCategory') {
        this.feedingProgramCategoryDBStoreEvent(data)
        return
      }
      if (model === 'Stock') {
        this.stockCategoryDBStoreEvent(data)
      }
    },
    feedingProgramCategoryDBUpdateEvent (data) {
      this.updateFeedingProgramCategoryList(data)
    },
    feedingProgramCategoryDBStoreEvent (data) {
      this.feedingProgramCategoryList.push(data)
    },
    stockCategoryDBStoreEvent (data) {
      this.insertToStockCategoryList(data)
    },
    updateFeedingProgramCategoryList (data) {
      data.id = parseInt(data.id, 10)
      this.feedingProgramCategoryList = this.feedingProgramCategoryList.map(item => {
        if (item.id === data.id) return Object.assign({ ...item, ...data })
        return Object.assign({ ...item })
      })
    },
    insertToStockCategoryList (newStockCategory) {
      this.stockCategoryList = this.stockCategoryList.objectInsertion(newStockCategory, 'id', 'id')
        .map(item => Object.assign({ ...item }))
    },
  },
}
</script>